<!------------------------------------------- template ------------------------------------------->
<template>
    <div class="view-root">
        <div class="page-header">
            <p class="common-title">通知设置</p>
            <el-button type="primary" @click="editNotification(null)" size="medium">新增</el-button>
        </div>

        <div class="table-margin-top flex-1-overflow-y">
            <el-table :data="dataList" style="width: 100%">
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <!-- <el-table-column prop="notice_title" label="通知名称" align="center"></el-table-column> -->
                <el-table-column prop="" label="通知类型" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.notice_type == 'custom' ? '定时发送' : '立即发送' }}
                    </template>
                </el-table-column>
                <el-table-column prop="meeting_name" label="会议名称" align="center"></el-table-column>
                <el-table-column prop="meeting_date" label="会议时间" align="center" width="140"></el-table-column>
                <el-table-column prop="meeting_address" label="会议地址" align="center" min-width="160"></el-table-column>
                <el-table-column prop="meeting_start_time" label="开始时间" align="center" min-width="160"></el-table-column>
                <el-table-column prop="meeting_organizer" label="举办单位" align="center" min-width="160"></el-table-column>

                <el-table-column label="操作" width="260" align="center">
                    <template slot-scope="scope">
                        <el-button size="medium" type="danger" @click="doPushNotification(scope.row._id.$id)"
                            style="margin-right: 10px;">发送</el-button>
                        <el-button size="medium" type="primary" @click="editNotification(scope.row)"
                            style="margin-right: 10px;">编辑</el-button>
                        <el-popconfirm placement="top-end" title="确认删除？" @confirm="actionRemove(scope.row)">
                            <el-button size="medium" type="primary" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 编辑弹窗 -->
        <el-dialog :visible.sync="isShowDialog" :title="`${formData.id ? '编辑' : '新增'}通知`">
            <div>
                <el-form :model="formData" :rules="rules" ref="form" label-width="80px">
                    <el-form-item label="会议名称" prop="meeting_name">
                        <el-input v-model="formData.meeting_name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="会议时间" prop="meeting_date">
                        <el-input v-model="formData.meeting_date" placeholder="格式：xxxx年xx月xx日"></el-input>
                    </el-form-item>
                    <el-form-item label="会议地点" prop="meeting_address">
                        <el-input v-model="formData.meeting_address" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="meeting_start_time">
                        <el-input v-model="formData.meeting_start_time" placeholder="格式：xxxx年xx月xx日 hh:mm 或者 hh:mm"></el-input>
                    </el-form-item>
                    <el-form-item label="举办单位" prop="meeting_organizer">
                        <el-input v-model="formData.meeting_organizer" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="通知时机" prop="sending_timing">
                        <el-radio-group v-model="formData.notice_type">
                            <el-radio label="custom">自定义</el-radio>
                            <el-radio label="now">立即发送</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="通知时间" prop="notice_time" v-if="formData.notice_type === 'custom'">
                        <el-date-picker v-model="formData.notice_time" type="datetime" value-format="timestamp"
                            placeholder="请选择">
                        </el-date-picker>
                    </el-form-item>
                </el-form>

                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="isShowDialog = false">取 消</el-button>
                        <el-button type="primary" @click="actionSave">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import '@/assets/common/common.css'
import {
    pushNotification,
    getNotificationList,
    updateNotification,
    deleteNotification,
} from '../../../api/api.js'
import { getUserId } from "../../../utils/store";

//https://blog.csdn.net/snans/article/details/117002436

export default {
    name: '',

    data() {
        return {
            isShowDialog: false,
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            formData: {
                id: '',
                meeting_id: this.meeting_id,
                meeting_name: '',
                meeting_date: '',
                meeting_address: '',
                meeting_start_time: '',
                meeting_organizer: '',
                notice_time: '',
                notice_type: '',
                creater_id: ''
            },
            rules: {
                meeting_name: [{ required: true, message: "Please input", trigger: "blur" }],
                meeting_date: [{ required: true, message: "Please input", trigger: "blur" }],
                meeting_address: [{ required: true, message: "Please input", trigger: "blur" }],
                meeting_start_time: [{ required: true, message: "Please input", trigger: "blur" }],
                meeting_organizer: [{ required: true, message: "Please input", trigger: "blur" }],




                notice_type: [{ required: true, message: "Please input", trigger: "blur" }],
            },
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        formatNoticeTime(notice_time) {
            return this.$dayjs(notice_time * 1000).format('YYYY-MM-DD HH:mm:ss')
        },

        doPushNotification(id) {
            this.$alert('你确定要Push Notification？, 我们将会按照通知类型进行立即发送或者定时发送通知', {
                callback: action => {
                    if (action == 'confirm') {
                        pushNotification(id).then((res) => {
                            let result = res.data
                            if (result.code == 0) {
                                this.$message.success('通知处理成功')
                            } else {
                                this.$message.warning(result.msg)
                            }
                        })
                    }
                }
            })
        },

        // 更新
        editNotification(row) {
            if (row) {
                this.formData = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    meeting_name: row.meeting_name,
                    meeting_date: row.meeting_date,
                    meeting_address: row.meeting_address,
                    meeting_start_time: row.meeting_start_time,
                    meeting_organizer: row.meeting_organizer,
                    notice_time: row.notice_time * 1000,
                    notice_type: row.notice_type,
                    creater_id: row.creater_id
                }
            } else {
                this.formData = {
                    id: '',
                    meeting_id: this.meeting_id,
                    meeting_name: '',
                    meeting_date: '',
                    meeting_address: '',
                    meeting_start_time: '',
                    meeting_organizer: '',
                    notice_time: '',
                    notice_type: '',
                    creater_id: getUserId()
                }
            }
            this.isShowDialog = true
        },
        // 保存
        actionSave() {
            this.$refs.form.validate((val) => {
                if (val) {
                    if (this.formData.notice_type == 'custom') {
                        this.formData.notice_time = parseInt(this.formData.notice_time / 1000)
                    } else {
                        this.formData.notice_time = parseInt(new Date().getTime() / 1000)
                    }
                    updateNotification(this.formData).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.isShowDialog = false
                    })
                }
            })
        },
        // 删除
        actionRemove(row) {
            this.$alert('你确定要删除该通知吗？删除该通知，所有的定时任务通知都被删除。', '提示', {
                callback: action => {
                    if (action == 'confirm') {
                        deleteNotification({ id: row._id.$id }).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
        fetchData() {
            getNotificationList({
                meeting_id: this.meeting_id,
            }).then(res => {
                if (res.data.code === 0) {
                    this.dataList = res.data.data
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
    }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center
}
</style>